frappe.call({
    method:"zelin_export_excel_template.api.get_export_excel_template"
})
.then((r) => {
    if (!r.exc && r.message && r.message.length){
        let uniqueDoctypes = [...new Set(r.message.map(item => item.reference_doctype))];
        uniqueDoctypes.forEach((dt) => {
            frappe.ui.form.on(dt, {
                refresh: function(frm) {
                    let data = r.message.filter(item => item.reference_doctype === dt);
                    if ( frappe.model.can_print(frm.doctype) && !frm.is_new()) {
                        let api_url = '/api/method/zelin_export_excel_template.api.export_data';
                        for (let d of data) {
                            frm.add_custom_button(__(d.template_name), function() {
                                if (d.is_multi_language){
                                    var dialog;
                                    dialog = new frappe.ui.Dialog({
                                        title: __('Select Language'),
                                        fields: [
                                            {
                                                fieldtype:"Link", 
                                                options:"Language",
                                                default: frappe.boot.lang,
                                                label:__("Language"), 
                                                reqd:1, 
                                                fieldname:"language"}			
                                        ],
                                        primary_action: function(values) {
                                            var data = values;			
                                            open_url_post(api_url, 
                                                {
                                                    doc:frm.doc, 
                                                    template_file:d.template_file,
                                                    template_name:d.template_name,
                                                    export_file_name: d.export_file_name  || "",
                                                    language:values.language
                                                }
                                            );
                                            dialog.hide();
                                        },
                                        primary_action_label: __('Export Excel')
                                    });
                                    dialog.show();
                                } else {                                        
                                    open_url_post(api_url, 
                                        {
                                            doc: frm.doc, 
                                            template_file: d.template_file,
                                            export_file_name: d.export_file_name || "",
                                            template_name: d.template_name,
                                        }
                                    );
                                }
                            }, __("Export Excel"))
                        }	
                    }
                }
            });            
        });
    }  
});